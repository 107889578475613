import React, { Component } from 'react';
import styles from '../styles/scss/pages/about.module.scss';
import NewLayout from '../layout/newLayout';
import SEO from "../components/seo"
class NST extends Component {
    render() {

        var pg = this.props.pageContext.page

        var openLink = () => {
            window.open('https://signup.e2ma.net/signup/1903994/1782733/', 'signup', 'menubar=no, location=no, toolbar=no, scrollbars=yes, height=500'); return false;
        }

        return (
            <NewLayout>
                {pg.seo.title && <h1 style={{display:'none'}}>{pg.seo.title}</h1>}
                <SEO page={pg} />
                <div className='d-flex flex-column flex-fill'>
                    <div className={styles.container}>

                        <div className="row text-center">
                            <div className="col-sm-12 text-center">
                                <img src="https://files.wd40.com/campaigns/UTI/2019/07-July/logos-wd40-uti-170h-outline.png" alt="Logos - WD-40 UTI" />
                            </div>
                        </div>

                        <div className="row text-center">
                            <div className="col-sm-12 text-center">
                                <img src="https://files.wd40.com/campaigns/UTI/2019/07-July/h1-its-for-you-pad-blue.png" width="100%" alt="It's Not For Everyone, It's For You" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <section className="content-module--textRow--AR2yv">
                                    <div className="d-block d-sm-none">&nbsp;</div>
                                    <p className="title-module--title--2pIiE text-center">
                                        Welcome to the WD-40<sub>&reg;</sub> Brand Loyalty Program.
                                    </p>
                                    <div>
                                        <p>
                                            <nobr>WD-40<sub>&reg;</sub></nobr> Brand would like to congratulate you on investing in your future. The career you have embarked on is in an exciting, cutting edge and constantly changing field. With your graduation, the transportation industry is gaining a promising &ndash; and necessary &ndash; new technician.
                                        </p>
                                        <p>
                                            As you embark on your career, you&rsquo;ll need the right tools for the job. WD-40 Brand has a full line of products, including lubricants, greases and penetrants that are designed specifically for professionals like you.
                                        </p>
                                        <p>
                                            Join the <nobr>WD-40</nobr> Brand Loyalty program to receive member-only rewards, like exclusive videos from your favorite auto influencers made just for UTI grads, auto industry news and product information and tips to help you get your job done right.
                                        </p>
                                        <p>
                                            Don&rsquo;t get caught on the job without the right tool. Sign up for the <nobr>WD-40</nobr> Company loyalty program to be the first to hear about brand new products, exclusive coupons for UTI grads and the auto content just for young professionals like you.
                                        </p>
                                    </div>
                                    <div className="contactUs-module--row--ZPY1m text-center">
                                        <a href="https://signup.e2ma.net/signup/1903994/1782733/" onClick={openLink} className="button-module--button--3JofU contactUs-module--input--19fYu button-module--blue--3Wjiz" role="button">
                                            Join Now
                                        </a>
                                    </div>
                                </section>
                            </div>
                        </div>
                        
                        <div className="row text-center">
                            <div className="col-sm-12 text-center">
                                <section className="content-module--textRow--AR2yv">
                                    <p>&nbsp;</p>
                                    <p>
                                        <img src="https://files.wd40.com/campaigns/UTI/2019/07-July/images-uti.png" width="100%" alt="UTI Images" />
                                    </p>
                                    <p>&nbsp;</p>
                                </section>
                            </div>
                        </div>

                    </div>
                </div>
            </NewLayout>
        );
    }
}

export default NST